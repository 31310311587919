<template>
  <component :is="loaded === false ? 'div' : 'div'">
    <b-card-actions
        ref="refreshCard"
        action-back
        action-save
        action-delete
        action-refresh
        @back="hRedirect('faq-categories-list')"
        @delete="helperDeleteItem(`/faq/categories/${currentItem.id}`, null, 'faq-categories-list')"
        @save="updateItem"
        @refresh="getItem"
        :title="this.currentItem.name"
        no-body
        disable-loading-on-refresh
        :loaded.snyc="loaded"
    >
    </b-card-actions>

    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <!-- Form -->
      <b-form @submit.stop.prevent="handleSubmit(updateItem)" @reset.prevent="resetForm">
        <b-card>
          <!-- Status -->
          <b-row>
            <b-col md="12" xl="12" class="mb-1 mt-2">
              <b-form-checkbox v-model="currentItem.active" value="1" name="active"
                               class="custom-control-success"
              >
                {{ $t('Active') }}
              </b-form-checkbox>
            </b-col>
            <b-col md="6" class="mb-1 mt-2">
              <b-form-group :label="$t('Area')">
                <b-form-select
                    v-model="currentItem.area"
                    :options="areas"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <!-- Name -->
            <b-col md="6" xl="6">
              <validation-provider #default="validationContext" name="name" rules="required">
                <b-form-group :label="$t('Name')">
                  <b-form-input id="name" v-model="currentItem.name"
                                trim :placeholder="$t('Name')"
                  />

                  <small class="text-danger">{{ validationContext.errors[0] }}</small>
                  <small v-if="validate && Object.keys(validation).includes('name')" class="text-danger">{{ $t(validation.name[0]) }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Code -->
            <b-col md="6" xl="6">
              <validation-provider #default="validationContext" name="position" rules="required">
                <b-form-group :label="$t('Position')">
                  <b-form-input id="code" v-model="currentItem.position"
                                trim
                                :placeholder="$t('Position')"
                                type="number"
                  />

                  <small class="text-danger">{{ validationContext.errors[0] }}</small>
                  <small v-if="validate && Object.keys(validation).includes('position')" class="text-danger">{{ $t(validation.position[0]) }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </b-card>
        <b-card>
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success"
                    type="submit"
          >
            {{ $t('Update') }}
          </b-button>
        </b-card>
      </b-form>
    </validation-observer>
  </component>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BAlert,
  BToast,
  BLink,
  BCard,
  BRow,
  BCol,
  BFormCheckbox,
  BFormSelect

} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'

import { required, alphaNum, email } from '@validations'
import BCardActions from '@core/components/b-card-actions/BCardActions'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BAlert,
    BToast,
    BLink,
    BCard,
    BRow,
    BCol,
    BFormCheckbox,
    BFormSelect,
    BCardActions,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      currentItem: {
        active: '',
        position: '',
        name: '',
      },
      areas: [
        {
          text: this.$t('Client'),
          value: 'client'
        },
        {
          text: this.$t('Expert'),
          value: 'expert'
        }
      ],
      loaded: true,
      validation: {},
      validate: false,
    }
  },

  methods: {
    getItem() {
      this.helperGetItem(`/faq/categories/${this.$route.params.id}`)
    },
    updateItem() {
      this.$refs.refFormObserver.validate().then((success) => {
        this.validation = {};
        this.validate = false;
        if (success) {
          this.helperUpdateItem(
            `/faq/categories/${this.$route.params.id}`,
            this.currentItem
          )
        }
      })
    },

    getValidationState({
      dirty,
      validated,
      valid = null
    }) {
      return dirty || validated ? valid : null
    },

  },
  mounted() {
    this.getItem()
  },
}
</script>

<style>
</style>
